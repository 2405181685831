import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { PipesModule } from '../../_pipes/pipes.module';
import { ProductCollectionsModule } from '../product-collections/product-collections.module';
import { ProductCollectionModule } from '../product-collection/product-collection.module';
import { ProductsCarouselModule } from '../products-carousel/products-carousel.module';
import { BannersModule } from '../banners/banners.module';
import { ProductContentModule } from '../product-content/product-content.module';
import { BlogCategoryModule } from 'src/@omnial/pages/blog-category/blog-category.module';
import { BlogsCarouselModule } from '../blogs-carousel/blogs-carousel.module';
import { BlogPostTeaserModule } from '../blog-post-teaser/blog-post-teaser.module';
import { WidgetComponent } from './widget.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialBaseModule,
    PipesModule,
    ProductCollectionsModule,
    ProductsCarouselModule,
    BannersModule,
    ProductCollectionModule,
    ProductContentModule,
    BlogPostTeaserModule,
    BlogCategoryModule,
    BlogsCarouselModule
  ],
  declarations: [WidgetComponent],
  exports: [WidgetComponent]
})
export class WidgetModule { }

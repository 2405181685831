import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { ProductDialogModule } from '../product-dialog/product-dialog.module';
import { RatingModule } from '../rating/rating.module';
import { ControlsListModule } from 'src/@omnial/product-controls/controls-list/controls-list.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PipesModule } from '../../_pipes/pipes.module';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';
import { ProductContentComponent } from './product-content.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialBaseModule,
    MaterialExtendModule,
    ProductDialogModule,
    RatingModule,
    ControlsListModule,
    LazyLoadImageModule,
    PipesModule
  ],
  declarations: [ProductContentComponent],
  exports: [ProductContentComponent]
})
export class ProductContentModule { }

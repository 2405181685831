import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';
import { PipesModule } from 'src/@omnial/_pipes/pipes.module';
import { ClientSharedModule } from 'src/app/shared/_client/client.shared.module';
import { BlogPostTeaserComponent } from './blog-post-teaser.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialBaseModule,
    MaterialExtendModule,
    RouterModule,
    LazyLoadImageModule,
    PipesModule,
    ClientSharedModule,
  ],
  declarations: [BlogPostTeaserComponent],
  exports: [BlogPostTeaserComponent]
})
export class BlogPostTeaserModule { }

<div style="position: relative; padding-bottom: 50px">
  <swiper-container #swiper init="false">
    <swiper-slide *ngFor="let post of posts">
      <div class="post-item">
        <a [routerLink]="[post.routerLink]" class="image-link">
          <div class="aspect-ratio-box">
            <div class="aspect-ratio-box-inside">
              <picture *ngIf="post.homePictureUrl">
                <source [attr.defaultImage]="settings?.holdingImage?.medium" [attr.lazyLoad]="post.homePictureUrl + '.webp'" type="image/webp" />
                <img [defaultImage]="settings?.holdingImage?.medium" [lazyLoad]="post.homePictureUrl" [alt]="post.title" />
              </picture>
            </div>
          </div>
        </a>
        <div class="d-flex flex-row flex-wrap justify-content-start">
          <div class="flex-100 post-title white-text">
            <span>{{post.title}}</span>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
</div>

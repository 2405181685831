import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogsCarouselComponent } from './blogs-carousel.component';
import { RouterModule } from '@angular/router';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialBaseModule,
    LazyLoadImageModule
  ],
  declarations: [BlogsCarouselComponent],
  exports: [BlogsCarouselComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class BlogsCarouselModule { }
